import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { ArrowBackIcon, BetterSleepIcon, LogoIcon } from 'components/SvgIcon';
import PropTypes from 'prop-types';
import { isEmpty } from 'helpers/common.helper';
import history from 'helpers/history.helper';
import { useTranslation } from 'react-i18next';
import BackBtnWithTitle from 'components/BackBtnWithTitle';

const SidebarTopClient = ({ backUrl = '' }) => {
    const { t } = useTranslation();
    return (
        <>
            <div id="sidebar-top">
                <a href="/">
                    <div id="logo" className="d-flex align-items-center">
                        <LogoIcon size={32} className="color-white mr-16" />
                        <BetterSleepIcon svgWidth={103} svgHeight={18} className="oem-color-gray" />
                    </div>
                </a>
            </div>
            {!isEmpty(backUrl) && (
                <BackBtnWithTitle className="sidebar-top-client" onClick={() => history.push(backUrl)} />
            )}
        </>
    );
};

export default connect()(SidebarTopClient);

SidebarTopClient.propTypes = {
    backUrl: PropTypes.string,
};
