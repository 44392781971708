import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BetterSleepIcon, LogoIcon, UserAccountIcon } from 'components/SvgIcon';
import sidebar from 'layouts/sidebar';
import SidebarItem from 'layouts/SidebarItem';
import history from 'helpers/history.helper';
import { setSidebarActive } from 'core.slice';
import { UserPropTypes } from 'constants/proptypes.constant';

const mapState = state => ({
    sidebarActive: state.core.sidebarActive,
    user: state.auth.user,
});
const mapDispatch = { setSidebarActive };
const SidebarAdmin = ({ sidebarActive, setSidebarActive, user }) => {
    const { t } = useTranslation();

    const badges = {
        ADMIN_COMPANY: user.unread_message || 0, // message unread count
    };

    return (
        <div id="sidebar-none-user" className="none-sidebar-user sidebar-light">
            <div id="logo">
                <LogoIcon size={32} className="color-red-100" />
            </div>

            <div id="user-info" className="p-0">
                <ul id="main-navigation" className="navigation">
                    <SidebarItem
                        isActive={sidebarActive === 'profile'}
                        key={'profile'}
                        icon={UserAccountIcon}
                        text={`${user.full_name}`}
                        isTranslate={false}
                        onClick={() => {
                            // if (sidebarActive === 'profile') return;
                            setSidebarActive('profile');
                            history.push('/admin/profile');
                        }}
                    />
                </ul>
            </div>

            <ul id="main-navigation" className="navigation">
                {sidebar.map(item => {
                    if (item.module !== 'admin') return null;
                    let badge;
                    if (Object.prototype.hasOwnProperty.call(badges, item.key)) {
                        badge = badges[item.key];
                    }
                    const isActive = sidebarActive === item.key;
                    return (
                        <SidebarItem
                            isActive={isActive}
                            key={item.key}
                            icon={item.icon}
                            text={item.text}
                            badge={badge}
                            onClick={() => {
                                // if (isActive) return;
                                setSidebarActive(item.key);
                                history.push(item.path);
                            }}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

export default connect(mapState, mapDispatch)(SidebarAdmin);

SidebarAdmin.propTypes = {
    sidebarActive: PropTypes.string,
    setSidebarActive: PropTypes.func.isRequired,
    user: UserPropTypes,
};
