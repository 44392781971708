import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import NewIssueModal from 'pages/admin/company/tabs/setting/NewIssueModal';
import UpdateStatusModal from 'pages/admin/company/tabs/setting/UpdateStatusModal';
import BackBtn from 'components/BackBtn';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { getEnumText } from 'helpers/enum.helper';
import CompanyStateEnum from 'enums/company_state.enum';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setCompanyCode, setCompanyState } from 'pages/admin/company/company_detail.slice';
import { connect } from 'react-redux';
import CompanyFormEdit from '../../CompanyFormEdit';
import { useParams } from 'react-router-dom';

const mapState = state => ({
    company: state.admin.companyDetail.company,
});
const mapDispatch = { setCompanyCode, setCompanyState };

const SettingContainer = ({ renderTabList, setCompanyCode, setCompanyState }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(true);

    const [modalVisible, setModalVisible] = useState({
        issue: false,
        status: false,
        deleteCompany: false,
    });

    const [tempCompanyCode, setTempCompanyCode] = useState(company.company_code);
    const [tempCompanyState, setTempCompanyState] = useState(company.state);

    const showIssueModal = () => setModalVisible({ issue: true, status: false, deleteCompany: false });
    const showStatusModal = () => setModalVisible({ status: true, issue: false, deleteCompany: false });
    const closeModal = () => setModalVisible({ issue: false, status: false, deleteCompany: false });

    const updateCompanyCode = async size => {
        const res = await api.patch(APP_URLS.ADMIN_COMPANIES_DETAIL_COMPANY_CODE.replace(':id', company.id), { size });
        if (res) {
            setTempCompanyCode(res);
            setCompanyCode(res);
        }
    };

    const updateCompanyState = async state => {
        const res = await api.patch(APP_URLS.ADMIN_COMPANIES_DETAIL_COMPANY_STATE.replace(':id', company.id), {
            state: state.state,
        });
        if (res) {
            setTempCompanyState(res);
            setCompanyState(res);
        }
    };

    const updateCompany = async values => {
        const response = await api.patch(APP_URLS.ADMIN_COMPANIES_UPDATE.replace(':id', id), values);
        if (response) {
            history.push(Routes.private.ADMIN_COMPANY.path);
        }
    };
    const getCompany = async id => {
        const response = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL.replace(':id', id));
        if (response) {
            setCompany(response);
            setTempCompanyCode(response.company_code);
            setTempCompanyState(response.state);
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompany(id).then(() => {});
    }, [id]);
    if (loading) return <></>;

    return (
        <>
            <div className="px-24 pb-12">
                <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />
            </div>
            {renderTabList('setting')}

            <div className="mb--20">
                <div className="">
                    <CompanyFormEdit isEdit={true} initialValues={company} onFinish={updateCompany} />
                </div>

                <h1 className="title-company-setting">{'企業コード'}</h1>

                <div className="mb-32">
                    <h3 className="title-company-value mb-8">{tempCompanyCode || '未発行'}</h3>
                    <p className="title-company-note mb-16">
                        {'相談者が新規登録時に企業コードを入力することで、企業に紐づきます。'}
                    </p>
                    <Button
                        type={tempCompanyCode ? 'outline' : 'primary'}
                        className="fw-b td-btn-w140"
                        onClick={() => showIssueModal()}
                    >
                        {tempCompanyCode ? '再発行' : '新規発行'}
                    </Button>
                </div>

                <NewIssueModal
                    visible={modalVisible.issue}
                    onClose={() => closeModal()}
                    initialValue={{}}
                    onUpdate={size => updateCompanyCode(size)}
                />

                <h1 className="title-company-setting">{'ステージ'}</h1>
                <div className="mb-16">{t(`enum:${getEnumText(CompanyStateEnum, tempCompanyState)}`)}</div>
                <div className="mb-72">
                    <Button className="fw-b td-btn-w140" onClick={() => showStatusModal()}>
                        {'ステージ変更'}
                    </Button>
                </div>

                <UpdateStatusModal
                    visible={modalVisible.status}
                    onClose={() => closeModal()}
                    initialValue={{ state: tempCompanyState }}
                    onUpdate={value => updateCompanyState(value)}
                    existCreatedReservation={company.existCreatedReservation}
                />
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(SettingContainer);

SettingContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    setCompanyCode: PropTypes.func,
    setCompanyState: PropTypes.func,
};
