import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AccessibilitySvg } from 'assets/images/icons/accessibility.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add.svg';
import { ReactComponent as ArrowBackSvg } from 'assets/images/icons/arrow_back.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/images/icons/arrow_down.svg';
import { ReactComponent as ArrowDropDownSvg } from 'assets/images/icons/arrow_drop_down.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/images/icons/arrow_left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/images/icons/arrow_right.svg';
import { ReactComponent as ArrowNextSvg } from 'assets/images/icons/arrow_next.svg';
import { ReactComponent as ArrowNextDropSvg } from 'assets/images/icons/arrow_next_drop.svg';
import { ReactComponent as ArrowNextDisabledSvg } from 'assets/images/icons/arrow_next_disabled.svg';
import { ReactComponent as ArrowPreviousSvg } from 'assets/images/icons/arrow_previous.svg';
import { ReactComponent as ArrowNextPrimarySvg } from 'assets/images/icons/arrow_next_primary.svg';
import { ReactComponent as ArrowPreviousPrimarySvg } from 'assets/images/icons/arrow_previous_primary.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/images/icons/arrow_up.svg';
import { ReactComponent as AssignmentSvg } from 'assets/images/icons/assignment.svg';
import { ReactComponent as AssignmentIndSvg } from 'assets/images/icons/assignment_ind.svg';
import { ReactComponent as BetterSleepSvg } from 'assets/images/icons/better_sleep.svg';
import { ReactComponent as CalendarSvg } from 'assets/images/icons/calendar.svg';
import { ReactComponent as CatSvg } from 'assets/images/icons/cat.svg';
import { ReactComponent as CopySvg } from 'assets/images/icons/copy.svg';
import { ReactComponent as CheckSvg } from 'assets/images/icons/check.svg';
import { ReactComponent as CheckboxSvg } from 'assets/images/icons/checkbox.svg';
import { ReactComponent as CheckmarkCheckedSvg } from 'assets/images/icons/checkmark_checked.svg';
import { ReactComponent as CheckmarkEmptySvg } from 'assets/images/icons/checkmark_empty.svg';
import { ReactComponent as CloseButtonImageSvg } from 'assets/images/icons/close_button_image.svg';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close.svg';
import { ReactComponent as CloseMenuSvg } from 'assets/images/icons/close_menu.svg';
import { ReactComponent as CyanCircleSvg } from 'assets/images/icons/cyan_circle.svg';
import { ReactComponent as DashboardSvg } from 'assets/images/icons/dashboard.svg';
import { ReactComponent as DateRangeSvg } from 'assets/images/icons/date_range.svg';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete.svg';
import { ReactComponent as DotsHorizontalRoundedSvg } from 'assets/images/icons/dots-horizontal-rounded.svg';
import { ReactComponent as DomainSvg } from 'assets/images/icons/domain.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/download.svg';
import { ReactComponent as DragIndicatorSvg } from 'assets/images/icons/drag_indicator.svg';
import { ReactComponent as DuplicateSvg } from 'assets/images/icons/dupplicate.svg';
import { ReactComponent as EditSvg } from 'assets/images/icons/edit.svg';
import { ReactComponent as EditorBoldSvg } from 'assets/images/icons/editor/format_bold.svg';
import { ReactComponent as EditorItalicSvg } from 'assets/images/icons/editor/format_italic.svg';
import { ReactComponent as EditorLinkSvg } from 'assets/images/icons/editor/link.svg';
import { ReactComponent as EditorListBulletedSvg } from 'assets/images/icons/editor/format_list_bulleted.svg';
import { ReactComponent as EditorListNumberedSvg } from 'assets/images/icons/editor/format_list_numbered.svg';
import { ReactComponent as EditorUnderlinedSvg } from 'assets/images/icons/editor/format_underlined.svg';
import { ReactComponent as EllipseSvg } from 'assets/images/icons/ellipse.svg';
import { ReactComponent as ErrorSvg } from 'assets/images/icons/error.svg';
import { ReactComponent as ExternalLinkSvg } from 'assets/images/icons/external_link.svg';
import { ReactComponent as EyeInputSvg } from 'assets/images/icons/eye_input.svg';
import { ReactComponent as FaceSvg } from 'assets/images/icons/face.svg';
import { ReactComponent as FormSvg } from 'assets/images/icons/form.svg';
import { ReactComponent as GroupSvg } from 'assets/images/icons/group.svg';
import { ReactComponent as HeartSvg } from 'assets/images/icons/heart.svg';
import { ReactComponent as HeartDisabledSvg } from 'assets/images/icons/heart_disabled.svg';
import { ReactComponent as InfoSvg } from 'assets/images/icons/info.svg';
import { ReactComponent as ImageSvg } from 'assets/images/icons/image.svg';
import { ReactComponent as ImageDisabledSvg } from 'assets/images/icons/image_disabled.svg';
import { ReactComponent as LineSvg } from 'assets/images/icons/line.svg';
import { ReactComponent as ListSvg } from 'assets/images/icons/list.svg';
import { ReactComponent as ListsSvg } from 'assets/images/icons/lists.svg';
import { ReactComponent as LoopSvg } from 'assets/images/icons/loop.svg';
import { ReactComponent as LockSvg } from 'assets/images/icons/lock.svg';
import { ReactComponent as LogoSvg } from 'assets/images/icons/logo.svg';
import { ReactComponent as LogoTextSvg } from 'assets/images/icons/logo-text.svg';
import { ReactComponent as MailSvg } from 'assets/images/icons/mail.svg';
import { ReactComponent as MenuSvg } from 'assets/images/icons/menu.svg';
import { ReactComponent as PauseSvg } from 'assets/images/icons/pause.svg';
import { ReactComponent as PressureSvg } from 'assets/images/icons/pressure.svg';
import { ReactComponent as PressureDisabledsvg } from 'assets/images/icons/pressure_disabled.svg';
import { ReactComponent as PurpleCirclesvg } from 'assets/images/icons/purple_circle.svg';
import { ReactComponent as RadioSvg } from 'assets/images/icons/radio.svg';
import { ReactComponent as RadioSelectedSvg } from 'assets/images/icons/radio_selected.svg';
import { ReactComponent as ReservationSvg } from 'assets/images/icons/reservation.svg';
import { ReactComponent as ReportProblemSvg } from 'assets/images/icons/report_problem.svg';
import { ReactComponent as RestrictSvg } from 'assets/images/icons/restrict.svg';
import { ReactComponent as SaveAltSvg } from 'assets/images/icons/save_alt.svg';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { ReactComponent as SessionSvg } from 'assets/images/icons/session.svg';
import { ReactComponent as SortSvg } from 'assets/images/icons/sort.svg';
import { ReactComponent as UserAccountSvg } from 'assets/images/icons/user_account.svg';
import { ReactComponent as VcSvg } from 'assets/images/icons/vc.svg';
import { ReactComponent as WeightSvg } from 'assets/images/icons/weight.svg';
import { ReactComponent as WeightDisabledSvg } from 'assets/images/icons/weight_disabled.svg';

const SvgIcon = ({ svg: SvgComponent, size = 24, svgWidth, svgHeight, className, onClick, ...props }) => {
    // const svgSize = size * 0.8334;
    const svgSize = size;
    return (
        <span
            role="img"
            aria-label="home"
            className={`anticon svg-icon ${className ? className : ''}`}
            onClick={onClick}
            {...props}
        >
            <SvgComponent width={`${svgWidth ? svgWidth : svgSize}px`} height={`${svgHeight ? svgHeight : svgSize}px`} />
        </span>
    );
};

export default SvgIcon;

SvgIcon.propTypes = {
    className: PropTypes.any,
    size: PropTypes.number,
    svg: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    svgWidth: PropTypes.number,
    svgHeight: PropTypes.number,
};

export const AccessibilityIcon = props => <SvgIcon svg={AccessibilitySvg} {...props} />;
export const AddIcon = props => <SvgIcon svg={AddSvg} {...props} />;
export const ArrowBackIcon = props => <SvgIcon svg={ArrowBackSvg} {...props} />;
export const ArrowDownIcon = props => <SvgIcon svg={ArrowDownSvg} {...props} />;
export const ArrowDropDownIcon = props => <SvgIcon svg={ArrowDropDownSvg} {...props} />;
export const ArrowLeftIcon = props => <SvgIcon svg={ArrowLeftSvg} {...props} />;
export const ArrowRightIcon = props => <SvgIcon svg={ArrowRightSvg} {...props} />;
export const ArrowNextIcon = props => <SvgIcon svg={ArrowNextSvg} {...props} />;
export const ArrowNextDropIcon = props => <SvgIcon svg={ArrowNextDropSvg} {...props} />;
export const ArrowNextDisabledIcon = props => <SvgIcon svg={ArrowNextDisabledSvg} {...props} />;
export const ArrowPreviousIcon = props => <SvgIcon svg={ArrowPreviousSvg} {...props} />;
export const ArrowNextPrimaryIcon = props => <SvgIcon svg={ArrowNextPrimarySvg} {...props} />;
export const ArrowPreviousPrimaryIcon = props => <SvgIcon svg={ArrowPreviousPrimarySvg} {...props} />;
export const ArrowUpIcon = props => <SvgIcon svg={ArrowUpSvg} {...props} />;
export const AssignmentIcon = props => <SvgIcon svg={AssignmentSvg} {...props} />;
export const AssignmentIndIcon = props => <SvgIcon svg={AssignmentIndSvg} {...props} />;
export const BetterSleepIcon = props => <SvgIcon svg={BetterSleepSvg} {...props} />;
export const CalendarIcon = props => <SvgIcon svg={CalendarSvg} {...props} />;
export const CatIcon = props => <SvgIcon svg={CatSvg} {...props} />;
export const CopyIcon = props => <SvgIcon svg={CopySvg} {...props} />;
export const CheckIcon = props => <SvgIcon svg={CheckSvg} {...props} />;
export const CheckboxIcon = props => <SvgIcon svg={CheckboxSvg} {...props} />;
export const CheckmarkCheckedIcon = props => <SvgIcon svg={CheckmarkCheckedSvg} {...props} />;
export const CheckmarkEmptyIcon = props => <SvgIcon svg={CheckmarkEmptySvg} {...props} />;
export const CloseButtonImageIcon = props => <SvgIcon svg={CloseButtonImageSvg} {...props} />;
export const CloseIcon = props => <SvgIcon svg={CloseSvg} {...props} />;
export const CloseMenuIcon = props => <SvgIcon svg={CloseMenuSvg} {...props} />;
export const CyanCircleIcon = props => <SvgIcon svg={CyanCircleSvg} {...props} />;
export const DashboardIcon = props => <SvgIcon svg={DashboardSvg} {...props} />;
export const DateRangeIcon = props => <SvgIcon svg={DateRangeSvg} {...props} />;
export const DeleteIcon = props => <SvgIcon svg={DeleteSvg} {...props} />;
export const DotsHorizontalRoundedIcon = props => <SvgIcon svg={DotsHorizontalRoundedSvg} {...props} />;
export const DomainIcon = props => <SvgIcon svg={DomainSvg} {...props} />;
export const DownloadIcon = props => <SvgIcon svg={DownloadSvg} {...props} />;
export const DragIndicatorIcon = props => <SvgIcon svg={DragIndicatorSvg} {...props} />;
export const DuplicateIcon = props => <SvgIcon svg={DuplicateSvg} {...props} />;
export const EditIcon = props => <SvgIcon svg={EditSvg} {...props} />;
export const EditorBoldIcon = props => <SvgIcon svg={EditorBoldSvg} {...props} />;
export const EditorItalicIcon = props => <SvgIcon svg={EditorItalicSvg} {...props} />;
export const EditorLinkIcon = props => <SvgIcon svg={EditorLinkSvg} {...props} />;
export const EditorListBulletedIcon = props => <SvgIcon svg={EditorListBulletedSvg} {...props} />;
export const EditorListNumberedIcon = props => <SvgIcon svg={EditorListNumberedSvg} {...props} />;
export const EditorUnderlinedIcon = props => <SvgIcon svg={EditorUnderlinedSvg} {...props} />;
export const EllipseIcon = props => <SvgIcon svg={EllipseSvg} {...props} />;
export const ErrorIcon = props => <SvgIcon svg={ErrorSvg} {...props} />;
export const EyeInputIcon = props => <SvgIcon svg={EyeInputSvg} {...props} />;
export const ExternalLinkIcon = props => <SvgIcon svg={ExternalLinkSvg} {...props} />;
export const FaceIcon = props => <SvgIcon svg={FaceSvg} {...props} />;
export const FormIcon = props => <SvgIcon svg={FormSvg} {...props} />;
export const GroupIcon = props => <SvgIcon svg={GroupSvg} {...props} />;
export const HeartIcon = props => <SvgIcon svg={HeartSvg} {...props} />;
export const HeartDisabledIcon = props => <SvgIcon svg={HeartDisabledSvg} {...props} />;
export const InfoIcon = props => <SvgIcon svg={InfoSvg} {...props} />;
export const ImageIcon = props => <SvgIcon svg={ImageSvg} {...props} />;
export const ImageDisabledIcon = props => <SvgIcon svg={ImageDisabledSvg} {...props} />;
export const LineIcon = props => <SvgIcon svg={LineSvg} {...props} />;
export const ListIcon = props => <SvgIcon svg={ListSvg} {...props} />;
export const ListsIcon = props => <SvgIcon svg={ListsSvg} {...props} />;
export const LoopIcon = props => <SvgIcon svg={LoopSvg} {...props} />;
export const LockIcon = props => <SvgIcon svg={LockSvg} {...props} />;
export const LogoIcon = props => <SvgIcon svg={LogoSvg} {...props} />;
export const LogoTextIcon = props => <SvgIcon svg={LogoTextSvg} {...props} />;
export const MailIcon = props => <SvgIcon svg={MailSvg} {...props} />;
export const MenuIcon = props => <SvgIcon svg={MenuSvg} {...props} />;
export const PauseIcon = props => <SvgIcon svg={PauseSvg} {...props} />;
export const PressureIcon = props => <SvgIcon svg={PressureSvg} {...props} />;
export const PressureDisabledIcon = props => <SvgIcon svg={PressureDisabledsvg} {...props} />;
export const PurpleCircleIcon = props => <SvgIcon svg={PurpleCirclesvg} {...props} />;
export const RadioIcon = props => <SvgIcon svg={RadioSvg} {...props} />;
export const RadioSelectedIcon = props => <SvgIcon svg={RadioSelectedSvg} {...props} />;
export const ReservationIcon = props => <SvgIcon svg={ReservationSvg} {...props} />;
export const ReportProblemIcon = props => <SvgIcon svg={ReportProblemSvg} {...props} />;
export const RestrictIcon = props => <SvgIcon svg={RestrictSvg} {...props} />;
export const SaveAltIcon = props => <SvgIcon svg={SaveAltSvg} {...props} />;
export const SearchIcon = props => <SvgIcon svg={SearchSvg} {...props} />;
export const SendIcon = props => <SvgIcon svg={SendSvg} {...props} />;
export const SessionIcon = props => <SvgIcon svg={SessionSvg} {...props} />;
export const SortIcon = props => <SvgIcon svg={SortSvg} {...props} />;
export const UserAccountIcon = props => <SvgIcon svg={UserAccountSvg} {...props} />;
export const VcIcon = props => <SvgIcon svg={VcSvg} {...props} />;
export const WeightIcon = props => <SvgIcon svg={WeightSvg} {...props} />;
export const WeightDisabledIcon = props => <SvgIcon svg={WeightDisabledSvg} {...props} />;
